<template>
  <div
    class="unsubscribe-message"
    :class="[`unsubscribe-message_${info.status}`]"
  >
    <template v-if="info.status === 'subscribeNotFound'">
      <h1 class="unsubscribe-message__title">
        Активных подписок<br />
        не найдено
      </h1>
      <p class="unsubscribe-message__desc">
        Для указанного Вами номера
        <strong>{{ data.phone | mask("+# ### #######") }}</strong
        ><br />
        активных подписок в системе не найдено.
      </p>
    </template>
    <template v-else>
      <p class="unsubscribe-message__desc" v-html="info.messages.msg"></p>
    </template>
    <div class="unsubscribe-message__action">
      <Button back @click.native="back()"> Вернуться </Button>
    </div>
  </div>
</template>

<script>
import Button from "@index/components/gui/button/Button";

import mask from "@index/helpers/string/setMask";

import "./unsubscribe-message.scss";

import store from "@index/store";

export default {
  name: "UnsubscribeMessage",
  computed: {
    info() {
      return this.$route.params.info;
    },
    data() {
      return this.$route.params.data;
    },
  },
  methods: {
    back() {
      this.$router.push({ name: "Unsubscribe" });
    },
  },
  components: {
    Button,
  },
  filters: {
    mask,
  },
  beforeRouteEnter(to, from, next) {
    const { info, data } = to.params;

    if (info && data) {
      next();
    } else {
      store.commit("application/load", false);
      next({
        name: "Unsubscribe",
      });
    }
  },
};
</script>
